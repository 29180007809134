@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




























































































































.result-subjects
  margin-top: 40px
  margin-bottom: 40px

  .wrapper
    +mq-m
      max-width: 100%

  section ~ section
    margin-top: 56px

    +mq-s
      margin-top: 40px

  & > section > h3
    margin-bottom: 24px
    box-sizing: border-box

    +mq-m
      padding: 0 24px

    +mq-s
      padding: 0 16px
