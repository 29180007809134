@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






































































































































































































.result-positions
  &__header
    display: grid
    grid-template-columns: 1fr 0.4fr
    align-items: color-ink-light
    column-gap: 40px
    margin-bottom: 24px

    +mq(860px)
      grid-template-columns: 100%
      gap: 24px

    +mq-m
      padding: 0 24px

    +mq-s
      padding: 0 16px

    &__title

      p
        margin-top: 8px
        color: $color-ink-light

  &__legend
    box-sizing: border-box
    padding: 16px
    border-radius: 8px
    background: transparentize($color-ink-lighter, .8)

    & > p
      margin-bottom: 16px

    .sas-chart-legend:not(:last-child)
      margin-bottom: 8px

  &__card
    +mq-l
      padding: 16px !important
    +mq-m
      border-radius: 0 !important

  &__loading
    width: 100%
    +flex-center
    padding: 80px
    box-sizing: border-box

  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 24px

    +mq-l
      gap: 16px

    +mq-m
      grid-template-columns: 100%

    .vertical-bar-chart

      &__bars
        +mq-l
          grid-template-columns: repeat(5, 48px) !important
          column-gap: 16px !important
          padding: 0 12px

        +mq(860px)
          column-gap: 12px !important

      &__x-label, &__y-label
        +mq-l
          font-size: 13px

      &__y-label
        +mq-l
          padding-left: 4px

        +mq-s
          display: none

      &__x-label
        +mq-l
          padding-top: 4px

        +mq-s
          display: none

    h4
      margin-bottom: 16px

