@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




































































































































































































.category-performance

  +mq-s
    overflow-x: scroll

    .data-table td:first-child,
    .data-table th:first-child
        padding-left: 16px

    .data-table th
      padding: 8px 8px

    .data-table td
      padding: 16px 8px
      text-align: center

  h3
    margin-bottom: 24px

  .category-name
    display: flex
    align-items: center

    i
      margin-left: 8px
